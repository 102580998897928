import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    AI: "AI-Projects",
    Decks: "SlideDecks",
    Other: "Other"
  };

  const projectsData = [
    {
      title: "Budget Alpha Zero",
      projectInfo:
        "I trained an AI to play chess using Alpha Zero methods. However due to budget I had to use supervised learning.",
      technologies: "Python, PyTorch",
      date: "March 25, 2023",
      url: {
        type: "Article:",
        name: "Training budget AlphaZero to play chess in PyTorch with a $8/month GPU.",
        link: "https://medium.com/@Hengbin/training-budget-alphazero-to-play-chess-with-an-8-month-gpu-in-pytorch-d8e3d2556c16",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-1-2.jpg",
      sliderImages: [],
      video: "x_HAgjdtlIE",
      categories: [filters.AI],
    },
    {
      title: "ChatGPT by phone call",
      projectInfo:
        "I'd like to thank my team mates Darren Su and Kohsuke Suzuki for helping us all win this Hackathon. The challenge was to use GPT to elevate the bottom billion.",
      technologies: "Python, Twilio, ChatGPT",
      date: "April 22, 2023",
      url: {
        type: "Code:",
        name: "ChatGPT by phone call",
        link: "https://github.com/HengbinFang/sms-gpt",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-2.jpg",
      sliderImages: [],
      video: "V7b2B-AP1u4",
      categories: [filters.AI],
    },
    {
      title: "AI to filter educational tiktok videos",
      projectInfo:
        "Similar idea was made with Youtube. Got inspiration from there.",
      technologies: "Python, OpenAI API + Fine Tune, Speech To Text, ProxiTok",
      date: "July, 2023",
      url: {
        type: "Article:",
        name: "Educational TikTok Filter With AI",
        link: "https://hengbin.medium.com/tiktok-videos-filtered-educationally-through-ai-c29c590d11e9",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-3.png",
      sliderImages: [],
      video: "HG1HZZB_bgc",
      categories: [filters.AI],
    },
    {
      title: "CNN Captcha Solver",
      projectInfo:
        "Was curious if I could use CNN's to solve the captcha's. Turns out I could, did this to learn.",
      technologies: "Python, FastAI, Colab",
      date: "Jan, 2023",
      url: {
        type: "Article:",
        name: "Modern Captchas Broken In A Few Lines Of Code",
        link: "https://hengbin.medium.com/modern-captchas-broken-in-a-few-lines-of-code-c27f13c01652",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-4.png",
      sliderImages: [],
      video: "",
      categories: [filters.AI],
    },
    {
      title: "Meta Consulting Slide Deck",
      projectInfo:
        "We consulted alongside Meta with my AI-Educational Filter idea.",
      technologies: "HTML/CSS/JS/Python/OpenAI API + Fine Tune",
      date: "Dec, 2023",
      url: {
        type: "Slide Deck:",
        name: "Empowering Teens on Meta",
        link: "https://firebasestorage.googleapis.com/v0/b/tks-life-prod.appspot.com/o/items%2FRIQi8bX6YzXyNfmmBaYlRWIIdA13%2FHengbin%2C%20Simone%2C%20Max%2C%20Ayaan%20-%20Fall%20TKS%20Challenge.pdf?alt=media&token=daaafcd3-af0d-4a86-9638-5aa3c17e6fab",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/o-projec-1.png",
      sliderImages: [],
      video: "", 
      categories: [filters.Decks],
    },
    {
      title: "Microsoft Consulting Slide Deck",
      projectInfo:
        "We consulted alongside Microsoft, recommending a solution to optimize voltages to save ~$2B.",
      technologies: "GPU benchmarking + stability testing",
      date: "Mar, 2024",
      url: {
        type: "Slide Deck:",
        name: "Saving $2B+ in Data Centers Globally With Voltage Underscaling",
        link: "https://firebasestorage.googleapis.com/v0/b/tks-life-prod.appspot.com/o/items%2FRIQi8bX6YzXyNfmmBaYlRWIIdA13%2FTKS%20x%20Microsoft%20Challenge%20_%20Lakshya%2C%20Hengbin%2C%20Nathan%2C%20Shehraan%2C%20Ayaan-1.pdf?alt=media&token=ac1b90f8-2716-493e-98a1-72c25038f932",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/o-projec-2.png",
      sliderImages: [],
      video: "", 
      categories: [filters.Decks],
    },
    {
      title: "Building a Transformer",
      projectInfo:
        "I built a Transformer in Python trained on Navals Almanack.",
      technologies: "Python + PyTorch + Colab",
      date: "Nov, 2023",
      url: {
        type: "Article:",
        name: "LLM trained on Navals Almanack — Behind ChatGPT’s Model",
        link: "https://hengbin.medium.com/llm-trained-on-navals-almanack-behind-chatgpts-model-19ce430225b7",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-5.png",
      sliderImages: [],
      video: "zcCKBaJLSH8",
      categories: [filters.AI],
    },
    {
      title: "Video call with Walter White",
      projectInfo:
        "I made it so that you can video-call with Walter White.",
      technologies: "HTML/CSS/JS + Python + OpenAI",
      date: "Nov, 2023",
      url: {
        type: "Article:",
        name: "Creating an AI you can video call with",
        link: "https://hengbin.medium.com/creating-an-ai-you-can-video-call-with-how-lip-syncing-works-e1f328624ce5",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-6.PNG",
      sliderImages: [],
      video: "lH3B31-Uako",
      categories: [filters.AI],
    },
    {
      title: "Use Google through SMS",
      projectInfo:
        "I built VeloGPT. A way people can use Google through SMS.",
      technologies: "Python + Twilio + OpenAI/Langchain + PostgreSQL",
      date: "Dec, 2023",
      url: {
        type: "Website:",
        name: "VeloGPT.tech",
        link: "https://velogpt.tech",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
      },
      thumbImage: "images/projects/projec-7.png",
      sliderImages: [],
      video: "67i9m57L1lY",
      categories: [filters.AI],
    },
  ];
  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
