import React from "react";
import PropTypes from "prop-types";


const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

// Not working yet | Fix when needed
function IfEmbed(video, thumbnail) {
  if (!!video || !!thumbnail) {
    if (!!video) {
      return (YoutubeEmbed(video))
    }
    return (
      <img
        className="img-fluid"
        alt=""
        src={thumbnail}
      />
    )
  }
  return (<></>)
  
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
