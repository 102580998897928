import React from "react";
const NewsletterSignup = ({ classicHeader, darkTheme }) => {
  return (
    <section id="newsletter" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Updates
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Monthly Newsletter
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          <div style={{ "text-align": "center"}} className="centre-align mx-auto col-lg-7 col-xl-8 text-center text-lg-start">
            <iframe src="https://hengbin.substack.com/embed" width="480" height="320" style={{border: "1px", solid: "#EEE", background: "white"}} frameborder="0" scrolling="no"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSignup;
